/* eslint-disable */
//COLORS
var Colors = {
    red:0xf25346,
    white:0xffffff,
    pink:0xF5986E,
    yellow:0xffb909,
    blue:0x1368dd,
    green:0x008837,
};

///////////////

// GAME VARIABLES
var game;
var deltaTime = 0;
var newTime = new Date().getTime();
var oldTime = new Date().getTime();
var ennemiesPool = [];
var particlesPool = [];
var particlesInUse = [];

function resetGame(){
  game = {speed:0,
          initSpeed:.00013,
          baseSpeed:.00013,
          targetBaseSpeed:.00028,
          incrementSpeedByTime:.0000020,
          incrementSpeedByLevel:.000005,
          distanceForSpeedUpdate:100,
          speedLastUpdate:0,

          distance:0,
          ratioSpeedDistance:50,
          energy:100,
          ratioSpeedEnergy:3,

          level:1,
          levelLastUpdate:0,
          distanceForLevelUpdate:1000,

          planeDefaultHeight:100,
          planeAmpHeight:80,
          planeAmpWidth:75,
          planeMoveSensivity:0.005,
          planeRotXSensivity:0.0008,
          planeRotZSensivity:0.0004,
          planeFallSpeed:.001,
          planeMinSpeed:1.2,
          planeMaxSpeed:1.6,
          planeSpeed:0,
          planeCollisionDisplacementX:0,
          planeCollisionSpeedX:0,

          planeCollisionDisplacementY:0,
          planeCollisionSpeedY:0,

          seaRadius:650,
          seaLength:800,
          //seaRotationSpeed:0.006,
          wavesMinAmp : 5,
          wavesMaxAmp : 20,
          wavesMinSpeed : 0.001,
          wavesMaxSpeed : 0.003,

          cameraFarPos:500,
          cameraNearPos:150,
          cameraSensivity:0.002,

          coinDistanceTolerance:15,
          coinValue:3,
          coinsSpeed:.5,
          coinLastSpawn:0,
          distanceForCoinsSpawn:100,

          ennemyDistanceTolerance:18,
          ennemyValue:10,
          ennemiesSpeed:.6,
          ennemyLastSpawn:0,
          distanceForEnnemiesSpawn:50,

          status : "playing",
         };
  fieldLevel.innerHTML = Math.floor(game.level);
}

//THREEJS RELATED VARIABLES

var scene,
    camera, fieldOfView, aspectRatio, nearPlane, farPlane,
    renderer,
    container,
    controls;

//SCREEN & MOUSE VARIABLES

var HEIGHT, WIDTH,
    mousePos = { x: 0, y: 0 };

//INIT THREE JS, SCREEN AND MOUSE EVENTS

function createScene() {

  HEIGHT = window.innerHeight-110;
  WIDTH = window.innerWidth;

  scene = new THREE.Scene();
  aspectRatio = WIDTH / HEIGHT;
  fieldOfView = 50;
  nearPlane = .1;
  farPlane = 10000;
  camera = new THREE.PerspectiveCamera(
    fieldOfView,
    aspectRatio,
    nearPlane,
    farPlane
  );
  scene.fog = new THREE.Fog({ alpha: true });
  camera.position.x = 0;
  camera.position.z = 200;
  camera.position.y = game.planeDefaultHeight;
  //camera.lookAt(new THREE.Vector3(0, 400, 0));

  renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
  renderer.setSize(WIDTH, HEIGHT);

  renderer.shadowMap.Enabled = true;

  container = document.getElementById('world');
  container.appendChild(renderer.domElement);

  window.addEventListener('resize', handleWindowResize, false);
}

// MOUSE AND SCREEN EVENTS

function handleWindowResize() {
  HEIGHT = window.innerHeight;
  WIDTH = window.innerWidth;
  renderer.setSize(WIDTH, HEIGHT);
  camera.aspect = WIDTH / HEIGHT;
  camera.updateProjectionMatrix();
}

function handleMouseMove(event) {
  // var tx = -1 + (event.clientX / WIDTH)*2;
  var ty = 1 - (event.clientY / HEIGHT)*2;
  mousePos = {x:0, y:ty};
}

function handleTouchMove(event) {
    event.preventDefault();
    var tx = -1 + (event.touches[0].pageX / WIDTH)*2;
    var ty = 1 - (event.touches[0].pageY / HEIGHT)*2;
    mousePos = {x:tx, y:ty};
}

function handleMouseUp(event){
  if (game.status == "waitingReplay"){
    resetGame();
    hideReplay();
  }
}


function handleTouchEnd(event){
  if (game.status == "waitingReplay"){
    resetGame();
    hideReplay();
  }
}

// LIGHTS

var ambientLight, hemisphereLight, shadowLight;

function createLights() {

  hemisphereLight = new THREE.HemisphereLight(0xaaaaaa,0x000000, .9)

  ambientLight = new THREE.AmbientLight(0xdc8874, .5);

  shadowLight = new THREE.DirectionalLight(0xffffff, .9);
  shadowLight.position.set(150, 350, 350);
  shadowLight.castShadow = true;
  shadowLight.shadow.camera.left = -400;
  shadowLight.shadow.camera.right = 400;
  shadowLight.shadow.camera.top = 400;
  shadowLight.shadow.camera.bottom = -400;
  shadowLight.shadow.camera.near = 1;
  shadowLight.shadow.camera.far = 1000;
  shadowLight.shadow.mapSize.width = 4096;
  shadowLight.shadow.mapSize.height = 4096;

  var ch = new THREE.CameraHelper(shadowLight.shadow.camera);

  scene.add(hemisphereLight);
  scene.add(shadowLight);
  scene.add(ambientLight);

}

var Bird = function(){
  this.mesh = new THREE.Object3D();
  this.mesh.name = "bird";

  // BIRD
  var geoHead = new THREE.SphereGeometry( 35, 64, 64 );
  var materialHead = new THREE.MeshLambertMaterial( {color: 0x337DFF} );
  var head = new THREE.Mesh( geoHead, materialHead );
  head.castShadow = true;
  head.receiveShadow = true;
  this.mesh.add(head);

  var geoBody = new THREE.SphereGeometry( 17, 64, 64 );
  var materialBody = new THREE.MeshLambertMaterial( {color: 0x337DFF} );
  var body = new THREE.Mesh( geoBody, materialBody );
  body.castShadow = true;
  body.receiveShadow = true;
  this.mesh.add(body);

  var geoTail = new THREE.SphereGeometry( 8, 64, 64 );
  var materialTail = new THREE.MeshLambertMaterial( {color: 0x337DFF} );
  var tail = new THREE.Mesh( geoTail, materialTail );
  tail.castShadow = true;
  tail.receiveShadow = true;
  this.mesh.add(tail);

  var geoTopknotB = new THREE.SphereGeometry( 14, 64, 64 );
  var materialTopknotB = new THREE.MeshLambertMaterial( {color: 0x337DFF} );
  this.topknotBig = new THREE.Mesh( geoTopknotB, materialTopknotB );
  this.topknotBig.castShadow = true;
  this.topknotBig.receiveShadow = true;
  this.mesh.add(this.topknotBig);

  var geoTopknotS = new THREE.SphereGeometry( 9, 64, 64 );
  var materialTopknotS = new THREE.MeshLambertMaterial( {color: 0x337DFF} );
  this.topknotSmall = new THREE.Mesh( geoTopknotS, materialTopknotS );
  this.topknotSmall.castShadow = true;
  this.topknotSmall.receiveShadow = true;
  this.mesh.add(this.topknotSmall);

  var geoWR = new THREE.CylinderGeometry( 17, 17, 4, 64, 64, false, 1, 3.1 );
  var materialWR = new THREE.MeshLambertMaterial( {color: 0xffb909} );

  this.wingRight = new THREE.Mesh( geoWR, materialWR );
  this.wingRight.castShadow = true;
  this.wingRight.receiveShadow = true;
  this.mesh.add(this.wingRight);

  var geoWL = new THREE.CylinderGeometry( 17, 17, 4, 64, 64, false, 5.2, 3.1 );
  var materialWL = new THREE.MeshLambertMaterial( {color: 0xffb909} );

  this.wingLeft = new THREE.Mesh( geoWL, materialWL );
  this.wingLeft.castShadow = true;
  this.wingLeft.receiveShadow = true;
  this.mesh.add(this.wingLeft);

  var geoEye = new THREE.TorusBufferGeometry( 8, 1.8, 4, 141, 3.2 );
  var materialEye = new THREE.MeshLambertMaterial( {
    color: 0x000000,
    transparent: false,
    opacity: 1
  } );
  this.rightEye = new THREE.Mesh( geoEye, materialEye );
  this.rightEye.rotation.y =.5;
  this.rightEye.castShadow = true;
  this.rightEye.receiveShadow = true;
  this.mesh.add(this.rightEye);

  this.leftEye = new THREE.Mesh( geoEye, materialEye );
  this.leftEye.rotation.y =-.5;
  this.leftEye.castShadow = true;
  this.leftEye.receiveShadow = true;
  this.mesh.add(this.leftEye);

  var geoEyeS = new THREE.TorusBufferGeometry( 8, 1.8, 4, 141, 3.2 );
  var materialEyeS = new THREE.MeshLambertMaterial( {
    color: 0x000000,
    transparent: true,
    opacity: 0
  });
  this.rightEyeD = new THREE.Mesh( geoEyeS, materialEyeS );
  this.rightEyeD.rotation.y =-.6;
  this.rightEyeD.rotation.x =3.4;
  this.rightEyeD.castShadow = true;
  this.rightEyeD.receiveShadow = true;
  this.rightEyeD.position.set(24,28,36);
  this.mesh.add(this.rightEyeD);

  this.leftEyeD = new THREE.Mesh( geoEyeS, materialEyeS );
  this.leftEyeD.rotation.y =-.6;
  this.leftEyeD.rotation.x =3.4;
  this.leftEyeD.castShadow = true;
  this.leftEyeD.receiveShadow = true;
  this.leftEyeD.position.set(17,28,-36);
  this.mesh.add(this.leftEyeD);

  var geoEyeF = new THREE.TorusBufferGeometry( 16, 1.8, 4, 141, 1.2 );
  var materialEyeF = new THREE.MeshLambertMaterial( {
    color: 0x000000,
    transparent: true,
    opacity: 0
  });
  this.rightEyeF = new THREE.Mesh( geoEyeF, materialEyeF );
  this.rightEyeF.rotation.y =-.2;
  this.rightEyeF.rotation.x =3.4;
  this.rightEyeF.castShadow = true;
  this.rightEyeF.receiveShadow = true;
  this.rightEyeF.position.set(18,28,40);
  this.mesh.add(this.rightEyeF);

  this.leftEyeF = new THREE.Mesh( geoEyeF, materialEyeF );
  this.leftEyeF.rotation.y =-.6;
  this.leftEyeF.rotation.x =3.4;
  this.leftEyeF.castShadow = true;
  this.leftEyeF.receiveShadow = true;
  this.leftEyeF.position.set(10,28,-40);
  this.mesh.add(this.leftEyeF);

  var geoEyeX = new THREE.TorusBufferGeometry( 16, 1.8, 4, 141, 1.2 );
  var materialEyeX = new THREE.MeshLambertMaterial( {
    color: 0x000000,
    transparent: true,
    opacity: 0
  });
  this.rightEyeX = new THREE.Mesh( geoEyeX, materialEyeX );
  this.rightEyeX.rotation.y =2.7;
  this.rightEyeX.rotation.x =3.4;
  this.rightEyeX.castShadow = true;
  this.rightEyeX.receiveShadow = true;
  this.rightEyeX.position.set(42,29,34);
  this.mesh.add(this.rightEyeX);

  this.leftEyeX = new THREE.Mesh( geoEyeX, materialEyeX );
  this.leftEyeX.rotation.y =2.7;
  this.leftEyeX.rotation.x =3.4;
  this.leftEyeX.castShadow = true;
  this.leftEyeX.receiveShadow = true;
  this.leftEyeX.position.set(34,29,-34);
  this.mesh.add(this.leftEyeX);

  var geoBeak = new THREE.TetrahedronGeometry(13,0);
  var materialBeak = new THREE.MeshLambertMaterial({
    color:0xffb909,
    specular:0xffb909,
    shading:THREE.FlatShading
  });
  var beak = new THREE.Mesh(geoBeak,materialBeak);
  beak.rotation.z =5.5;
  beak.rotation.y = 2.4;
  beak.castShadow = true;
  beak.receiveShadow = true;
  this.mesh.add(beak);

  head.position.set(4,25,0);
  body.position.set(-26,-10,0);
  tail.position.set(-47,-13,0);
  this.topknotBig.position.set(17,60,0);
  this.topknotSmall.position.set(31,49,0);
  this.wingRight.position.set(-18,1,20);
  this.wingLeft.position.set(-24,1,-20);
  this.rightEye.position.set(24,20,31);
  this.leftEye.position.set(17,20,-31);
  beak.position.set(37,19,-15);
};

Environments = function(){
  this.mesh = new THREE.Object3D();
  this.nClouds = 15;
  this.clouds = [];
  var stepAngle = Math.PI*2 / this.nClouds;
  for(var i=0; i<this.nClouds; i++){
    var a = stepAngle*i;
    var h = 750 + Math.random();

    var tree1 = new FirstTree();
    this.clouds.push(tree1);
    tree1.mesh.position.y = Math.sin(a)*h;
    tree1.mesh.position.x = Math.cos(a)*h;
    tree1.mesh.rotation.z = a + Math.PI/2;
    tree1.mesh.position.z = -400-Math.random()*400;
    this.mesh.add(tree1.mesh);

    var tree2 = new SecondTree();
    this.clouds.push(tree2);
    tree2.mesh.position.y = Math.sin(a)*h;
    tree2.mesh.position.x = Math.cos(a)*h;
    tree2.mesh.rotation.z = a + Math.PI/2;
    tree2.mesh.position.z = -400-Math.random()*400;
    this.mesh.add(tree2.mesh);

    var build1 = new Building();
    this.clouds.push(build1);
    build1.mesh.position.y = Math.sin(a)*h;
    build1.mesh.position.x = Math.cos(a)*h;
    build1.mesh.rotation.z = a + Math.PI/2;
    build1.mesh.position.z = -400-Math.random()*400;
    this.mesh.add(build1.mesh);

    var build2 = new Building2();
    this.clouds.push(build2);
    build2.mesh.position.y = Math.sin(a)*h;
    build2.mesh.position.x = Math.cos(a)*h;
    build2.mesh.rotation.z = a + Math.PI/2;
    build2.mesh.position.z = -400-Math.random()*400;
    this.mesh.add(build2.mesh);
  }
}

FirstTree = function(){
  this.mesh = new THREE.Object3D();
  this.mesh.name = "tree";

  var geomTree = new THREE.CylinderGeometry(3, 3, 38, 32);
  var matTree = new THREE.MeshPhongMaterial({color:0x000000});
  var a = new THREE.Mesh(geomTree, matTree);
  a.position.set(0,146,300);
  a.castShadow = true;
  a.receiveShadow = true;
  this.mesh.add(a);

  var geomTree = new THREE.DodecahedronGeometry(18, 1);
  var matTree = new THREE.MeshPhongMaterial({color:0x008837});
  var b = new THREE.Mesh(geomTree, matTree);
  b.position.set(0,126,300);
  b.castShadow = true;
  b.receiveShadow = true;
  this.mesh.add(b);
}

SecondTree = function(){
  this.mesh = new THREE.Object3D();
  this.mesh.name = "secondtree";

  var geomTree = new THREE.CylinderGeometry(3, 3, 28, 32);
  var matTree = new THREE.MeshPhongMaterial({color:0x000000});
  var a = new THREE.Mesh(geomTree, matTree);
  a.position.set(-40,156,300);
  a.castShadow = true;
  a.receiveShadow = true;
  this.mesh.add(a);

  var geomTree = new THREE.DodecahedronGeometry(14, 1);
  var matTree = new THREE.MeshPhongMaterial({color:0x40a629});
  var b = new THREE.Mesh(geomTree, matTree);
  b.position.set(-40,135,300);
  b.castShadow = true;
  b.receiveShadow = true;
  this.mesh.add(b);
}

Building = function(){
  this.mesh = new THREE.Object3D();
  this.mesh.name = "building";

  var geom = new THREE.CylinderGeometry(11, 11, 60, 4);
  var mat = new THREE.MeshPhongMaterial({color:0x808080});
  var a = new THREE.Mesh(geom, mat);
  a.position.set(50,136,300);
  a.rotation.z = .05;
  a.castShadow = true;
  a.receiveShadow = true;
  this.mesh.add(a);
}

Building2 = function(){
  this.mesh = new THREE.Object3D();
  this.mesh.name = "building";

  var geom = new THREE.CylinderGeometry(11, 11, 60, 4);
  var mat = new THREE.MeshPhongMaterial({color:0xCCCCCC});
  var a = new THREE.Mesh(geom, mat);
  a.position.set(90,166,300);
  a.rotation.z = .15;
  a.castShadow = true;
  a.receiveShadow = true;
  this.mesh.add(a);
}

Sky = function(){
  this.mesh = new THREE.Object3D();
  this.nClouds = 10;
  this.clouds = [];
  var stepAngle = Math.PI*2 / this.nClouds;
  for(var i=0; i<this.nClouds; i++){
    var a = stepAngle*i;
    var h = game.seaRadius + 150 + Math.random()*200;
    var s = .2+Math.random()*2;

    var c = new Cloud();
    this.clouds.push(c);
    c.mesh.position.y = Math.sin(a)*h;
    c.mesh.position.x = Math.cos(a)*h;
    c.mesh.position.z = -300-Math.random()*500;
    c.mesh.rotation.z = a + Math.PI/2;
    c.mesh.scale.set(s,s,s);
    this.mesh.add(c.mesh);

    var d = new Cloud2();
    this.clouds.push(d);
    d.mesh.position.y = Math.sin(a)*h;
    d.mesh.position.x = Math.cos(a)*h;
    d.mesh.position.z = -300-Math.random()*500;
    d.mesh.rotation.z = a + Math.PI/2;
    d.mesh.scale.set(s,s,s);
    this.mesh.add(d.mesh);

    var e = new Cloud3();
    this.clouds.push(e);
    e.mesh.position.y = Math.sin(a)*h;
    e.mesh.position.x = Math.cos(a)*h;
    e.mesh.position.z = -300-Math.random()*500;
    e.mesh.rotation.z = a + Math.PI/2;
    e.mesh.scale.set(s,s,s);
    this.mesh.add(e.mesh);
  }
}

Sky.prototype.moveClouds = function(){
  this.mesh.rotation.z += game.speed*deltaTime;
}

Cloud = function(){
  this.mesh = new THREE.Object3D();
  this.mesh.name = "cloud";

  var geom = new THREE.DodecahedronGeometry(26,3);
  var mat = new THREE.MeshPhongMaterial({
    color:Colors.white,
  });
  var a = new THREE.Mesh(geom, mat);
  a.position.set(0,0,0);
  a.rotation.z = .15;
  this.mesh.add(a);

  var geom = new THREE.DodecahedronGeometry(16,3);
  var mat = new THREE.MeshPhongMaterial({
    color:Colors.white,
  });
  var b = new THREE.Mesh(geom, mat);
  b.position.set(-32,0,0);
  b.rotation.z = .15;
  this.mesh.add(b);

  var geom = new THREE.DodecahedronGeometry(13,3);
  var mat = new THREE.MeshPhongMaterial({
    color:Colors.white,
  });
  var c = new THREE.Mesh(geom, mat);
  c.position.set(25,16,0);
  c.rotation.z = .15;
  this.mesh.add(c);
}

Cloud2 = function(){
  this.mesh = new THREE.Object3D();
  this.mesh.name = "cloud2";

  var geom = new THREE.DodecahedronGeometry(26,3);
  var mat = new THREE.MeshPhongMaterial({
    color:Colors.white,
  });
  var a = new THREE.Mesh(geom, mat);
  a.position.set(-100,0,0);
  a.rotation.z = .15;
  this.mesh.add(a);

  var geom = new THREE.DodecahedronGeometry(16,3);
  var mat = new THREE.MeshPhongMaterial({
    color:Colors.white,
  });
  var b = new THREE.Mesh(geom, mat);
  b.position.set(-132,0,0);
  b.rotation.z = .15;
  this.mesh.add(b);
}

Cloud3 = function(){
  this.mesh = new THREE.Object3D();
  this.mesh.name = "cloud";

  var geom = new THREE.DodecahedronGeometry(26,3);
  var mat = new THREE.MeshPhongMaterial({
    color:Colors.white,
  });
  var a = new THREE.Mesh(geom, mat);
  a.position.set(140,0,0);
  a.rotation.z = .15;
  this.mesh.add(a);

  var geom = new THREE.DodecahedronGeometry(16,3);
  var mat = new THREE.MeshPhongMaterial({
    color:Colors.white,
  });
  var b = new THREE.Mesh(geom, mat);
  b.position.set(108,0,0);
  b.rotation.z = .15;
  this.mesh.add(b);

  var geom = new THREE.DodecahedronGeometry(16,3);
  var mat = new THREE.MeshPhongMaterial({
    color:Colors.white,
  });
  var c = new THREE.Mesh(geom, mat);
  c.position.set(165,11,0);
  c.rotation.z = .15;
  this.mesh.add(c);

  var geom = new THREE.DodecahedronGeometry(12,3);
  var mat = new THREE.MeshPhongMaterial({
    color:Colors.white,
  });
  var d = new THREE.Mesh(geom, mat);
  d.position.set(180,18,0);
  d.rotation.z = .15;
  this.mesh.add(d);
}

Sea = function(){
  var geom = new THREE.CylinderGeometry(game.seaRadius,game.seaRadius,game.seaLength,40,10);
  geom.applyMatrix(new THREE.Matrix4().makeRotationX(-Math.PI/2));
  geom.mergeVertices();
  var l = geom.vertices.length;
  var mat = new THREE.MeshPhongMaterial({
    color:Colors.blue,
    shading:THREE.FlatShading,
  });

  this.mesh = new THREE.Mesh(geom, mat);
  this.mesh.name = "waves";
  this.mesh.receiveShadow = true;

}

Cloud.prototype.rotate = function(){
  var l = this.mesh.children.length;
  for(var i=0; i<l; i++){
    var m = this.mesh.children[i];
    m.rotation.z+= Math.random()*.005*(i+1);
    m.rotation.y+= Math.random()*.002*(i+1);
  }
}

Ennemy = function(){
  this.mesh = new THREE.Object3D();
  this.mesh.name = "bombEnemy";

  var geomBody = new THREE.SphereBufferGeometry(8,22,22);
  var matBody = new THREE.MeshPhongMaterial({
    color:0x121212,
    shininess:0,
    specular:0xffffff,
    shading:THREE.FlatShading
  });
  var bombBody = new THREE.Mesh(geomBody, matBody);
  bombBody.castShadow = true;
  bombBody.angle = 0;
  bombBody.dist = 0;
  this.mesh.add(bombBody);

  var geomBody = new THREE.CylinderBufferGeometry(3, 3, 11, 64);
  var matBody = new THREE.MeshPhongMaterial({
    color:0x121212,
    shininess:0,
    specular:0xffffff,
    shading:THREE.FlatShading
  });
  var bombBody = new THREE.Mesh(geomBody, matBody);
  bombBody.castShadow = true;
  bombBody.position.set(0,3.8,0);
  bombBody.angle = 0;
  bombBody.dist = 0;
  this.mesh.add(bombBody);

  function CustomSinCurve( scale ) {

  	THREE.Curve.call( this );

  	this.scale = ( scale === undefined ) ? 1 : scale;

  }

  CustomSinCurve.prototype = Object.create( THREE.Curve.prototype );
  CustomSinCurve.prototype.constructor = CustomSinCurve;

  CustomSinCurve.prototype.getPoint = function ( t ) {

  	var tx = t * 3 - 1.5;
  	var ty = Math.sin( 2 * Math.PI * t );
  	var tz = 0;

  	return new THREE.Vector3( tx, ty, tz ).multiplyScalar( this.scale );

  };

  var path = new CustomSinCurve( 3 );
  var geometry = new THREE.TubeGeometry( path, 20, 1, 8, false );
  var material = new THREE.MeshBasicMaterial( { color: 0x8f4a1e } );
  var bombFuse = new THREE.Mesh( geometry, material );
  bombFuse.position.set(5,9,0);
  bombFuse.angle = 0;
  bombFuse.dist = 0;
  this.mesh.add(bombFuse);
}

EnnemiesHolder = function (){
  this.mesh = new THREE.Object3D();
  this.ennemiesInUse = [];
}

EnnemiesHolder.prototype.spawnEnnemies = function(){
  var nEnnemies = game.level;

  for (var i=0; i<nEnnemies; i++){
    var ennemy;
    if (ennemiesPool.length) {
      ennemy = ennemiesPool.pop();
    }else{
      ennemy = new Ennemy();
    }

    ennemy.angle = - (i*0.1);
    ennemy.distance = game.seaRadius + game.planeDefaultHeight + (-1 + Math.random() * 2) * (game.planeAmpHeight-20);
    ennemy.mesh.position.y = -game.seaRadius + Math.sin(ennemy.angle)*ennemy.distance;
    ennemy.mesh.position.x = Math.cos(ennemy.angle)*ennemy.distance;

    this.mesh.add(ennemy.mesh);
    this.ennemiesInUse.push(ennemy);
  }
}

EnnemiesHolder.prototype.rotateEnnemies = function(){
  for (var i=0; i<this.ennemiesInUse.length; i++){
    var ennemy = this.ennemiesInUse[i];
    ennemy.angle += game.speed*deltaTime*game.ennemiesSpeed;

    if (ennemy.angle > Math.PI*2) ennemy.angle -= Math.PI*2;

    ennemy.mesh.position.y = -game.seaRadius + Math.sin(ennemy.angle)*ennemy.distance;
    ennemy.mesh.position.x = Math.cos(ennemy.angle)*ennemy.distance;
    ennemy.mesh.rotation.z += Math.random()*.001;
    ennemy.mesh.rotation.y += Math.random()*.1;

    //var globalEnnemyPosition =  ennemy.mesh.localToWorld(new THREE.Vector3());
    var diffPos = bird.mesh.position.clone().sub(ennemy.mesh.position.clone());
    var d = diffPos.length();
    if (d<game.ennemyDistanceTolerance){
      particlesHolder.spawnParticles(ennemy.mesh.position.clone(), 15, Colors.red, 3);

      ennemiesPool.unshift(this.ennemiesInUse.splice(i,1)[0]);
      this.mesh.remove(ennemy.mesh);
      game.planeCollisionSpeedX = 100 * diffPos.x / d;
      game.planeCollisionSpeedY = 100 * diffPos.y / d;
      ambientLight.intensity = 2;

      removeEnergy();
      i--;
    }else if (ennemy.angle > Math.PI){
      ennemiesPool.unshift(this.ennemiesInUse.splice(i,1)[0]);
      this.mesh.remove(ennemy.mesh);
      i--;
    }

    // console.log('continue')
  }
}

Particle = function(){
  var geom = new THREE.TetrahedronGeometry(3,0);
  var mat = new THREE.MeshPhongMaterial({
    color:0xffb909,
    shininess:0,
    specular:0xffffff,
    shading:THREE.FlatShading
  });
  this.mesh = new THREE.Mesh(geom,mat);
}

Particle.prototype.explode = function(pos, color, scale){
  var _this = this;
  var _p = this.mesh.parent;
  this.mesh.material.color = new THREE.Color( color);
  this.mesh.material.needsUpdate = true;
  this.mesh.scale.set(scale, scale, scale);
  var targetX = pos.x + (-1 + Math.random()*2)*50;
  var targetY = pos.y + (-1 + Math.random()*2)*50;
  var speed = .6+Math.random()*.2;
  TweenMax.to(this.mesh.rotation, speed, {x:Math.random()*12, y:Math.random()*12});
  TweenMax.to(this.mesh.scale, speed, {x:.1, y:.1, z:.1});
  TweenMax.to(this.mesh.position, speed, {x:targetX, y:targetY, delay:Math.random() *.1, ease:Power2.easeOut, onComplete:function(){
      if(_p) _p.remove(_this.mesh);
      _this.mesh.scale.set(.2,.2,.2);
      particlesPool.unshift(_this);
    }});
}

ParticlesHolder = function (){
  this.mesh = new THREE.Object3D();
  this.particlesInUse = [];
}

ParticlesHolder.prototype.spawnParticles = function(pos, density, color, scale){

  var nPArticles = density;
  for (var i=0; i<nPArticles; i++){
    var particle;
    if (particlesPool.length) {
      particle = particlesPool.pop();
    }else{
      particle = new Particle();
    }
    this.mesh.add(particle.mesh);
    particle.mesh.visible = true;
    var _this = this;
    particle.mesh.position.y = pos.y;
    particle.mesh.position.x = pos.x;
    particle.explode(pos,color, scale);
  }
}

Coin = function(){
  var geom = new THREE.CylinderGeometry( 5, 5, 2, 64 );
  var mat = new THREE.MeshPhongMaterial({
    color:0xffb909,
    shininess:0,
    specular:0xffffff,
    shading:THREE.FlatShading
  });
  this.mesh = new THREE.Mesh(geom,mat);
  this.mesh.castShadow = true;
  this.angle = 0;
  this.dist = 0;
}

CoinsHolder = function (nCoins){
  this.mesh = new THREE.Object3D();
  this.coinsInUse = [];
  this.coinsPool = [];
  for (var i=0; i<nCoins; i++){
    var coin = new Coin();
    this.coinsPool.push(coin);
  }
}

CoinsHolder.prototype.spawnCoins = function(){

  var nCoins = 1 + Math.floor(Math.random()*10);
  var d = game.seaRadius + game.planeDefaultHeight + (-1 + Math.random() * 2) * (game.planeAmpHeight-20);
  var amplitude = 10 + Math.round(Math.random()*10);
  for (var i=0; i<nCoins; i++){
    var coin;
    if (this.coinsPool.length) {
      coin = this.coinsPool.pop();
    }else{
      coin = new Coin();
    }
    this.mesh.add(coin.mesh);
    this.coinsInUse.push(coin);
    coin.angle = - (i*0.02);
    coin.distance = d + Math.cos(i*.5)*amplitude;
    coin.mesh.position.y = -game.seaRadius + Math.sin(coin.angle)*coin.distance;
    coin.mesh.position.x = Math.cos(coin.angle)*coin.distance;
  }
}

CoinsHolder.prototype.rotateCoins = function(){
  for (var i=0; i<this.coinsInUse.length; i++){
    var coin = this.coinsInUse[i];
    if (coin.exploding) continue;
    coin.angle += game.speed*deltaTime*game.coinsSpeed;
    if (coin.angle>Math.PI*2) coin.angle -= Math.PI*2;
    coin.mesh.position.y = -game.seaRadius + Math.sin(coin.angle)*coin.distance;
    coin.mesh.position.x = Math.cos(coin.angle)*coin.distance;
    coin.mesh.rotation.z += Math.random()*.1;
    coin.mesh.rotation.y += Math.random()*.1;

    //var globalCoinPosition =  coin.mesh.localToWorld(new THREE.Vector3());
    var diffPos = bird.mesh.position.clone().sub(coin.mesh.position.clone());
    var d = diffPos.length();
    if (d<game.coinDistanceTolerance){
      this.coinsPool.unshift(this.coinsInUse.splice(i,1)[0]);
      this.mesh.remove(coin.mesh);
      particlesHolder.spawnParticles(coin.mesh.position.clone(), 5, 0xffb909, .8);
      addEnergy();
      i--;
    }else if (coin.angle > Math.PI){
      this.coinsPool.unshift(this.coinsInUse.splice(i,1)[0]);
      this.mesh.remove(coin.mesh);
      i--;
    }
  }
}


// 3D Models
var sea;
var bird;
var tree;
var score = 0;
var high_score = 0;

function createPlane(){
  bird = new Bird();
  bird.mesh.scale.set(.21,.21,.21);
  bird.castShadow = true;
  bird.receiveShadow = true;
  bird.mesh.position.y = game.planeDefaultHeight;
  scene.add(bird.mesh);
}

function createSea(){
  sea = new Sea();
  sea.mesh.position.y = -game.seaRadius;
  scene.add(sea.mesh);
}

function createEnvironment(){
  environment = new Environments();
  environment.mesh.position.y = -600;
  scene.add(environment.mesh);
}

function createSky(){
  sky = new Sky();
  sky.mesh.position.y = -game.seaRadius;
  scene.add(sky.mesh);
}

function createCoins(){

  coinsHolder = new CoinsHolder(20);
  scene.add(coinsHolder.mesh)
}

function createEnnemies(){
  for (var i=0; i<10; i++){
    var ennemy = new Ennemy();
    ennemiesPool.push(ennemy);
  }
  ennemiesHolder = new EnnemiesHolder();
  scene.add(ennemiesHolder.mesh)
}

function createParticles(){
  for (var i=0; i<10; i++){
    var particle = new Particle();
    particlesPool.push(particle);
  }
  particlesHolder = new ParticlesHolder();
  scene.add(particlesHolder.mesh)
}

function loop(){

  newTime = new Date().getTime();
  deltaTime = newTime-oldTime;
  oldTime = newTime;

  if (game.status=="playing"){

    // Add energy coins every 100m;
    if (Math.floor(game.distance)%game.distanceForCoinsSpawn == 0 && Math.floor(game.distance) > game.coinLastSpawn){
      game.coinLastSpawn = Math.floor(game.distance);
      coinsHolder.spawnCoins();
    }

    if (Math.floor(game.distance)%game.distanceForSpeedUpdate == 0 && Math.floor(game.distance) > game.speedLastUpdate){
      game.speedLastUpdate = Math.floor(game.distance);
      game.targetBaseSpeed += game.incrementSpeedByTime*deltaTime;
    }

    if (Math.floor(game.distance)%game.distanceForEnnemiesSpawn == 0 && Math.floor(game.distance) > game.ennemyLastSpawn){
      game.ennemyLastSpawn = Math.floor(game.distance);
      ennemiesHolder.spawnEnnemies();
    }

    if (Math.floor(game.distance)%game.distanceForLevelUpdate == 0 && Math.floor(game.distance) > game.levelLastUpdate){
      game.levelLastUpdate = Math.floor(game.distance);
      game.level++;
      fieldLevel.innerHTML = Math.floor(game.level);

      game.targetBaseSpeed = game.initSpeed + game.incrementSpeedByLevel*game.level
    }

    updatePlane();
    updateDistance();
    updateEnergy();
    game.baseSpeed += (game.targetBaseSpeed - game.baseSpeed) * deltaTime * 0.02;
    game.speed = game.baseSpeed * game.planeSpeed;

  }else if(game.status=="gameover"){
    game.speed *= .99;
    bird.mesh.rotation.z += (-Math.PI/2 - bird.mesh.rotation.z)*.0002*deltaTime;
    bird.mesh.rotation.x += 0.0003*deltaTime;
    game.planeFallSpeed *= 1.05;
    bird.mesh.position.y -= game.planeFallSpeed*deltaTime;

    if (high_score < score) {
      high_score = score;
    }

    setDeadEyes();

    highScore.innerHTML = high_score;

    if (bird.mesh.position.y <-200){
      showReplay();
      game.status = "waitingReplay";
    }
  }else if (game.status=="waitingReplay"){

  }

  sea.mesh.rotation.z += game.speed*deltaTime;//*game.seaRotationSpeed;
  environment.mesh.rotation.z += .01;

  if ( sea.mesh.rotation.z > 2*Math.PI)  sea.mesh.rotation.z -= 2*Math.PI;

  ambientLight.intensity += (.5 - ambientLight.intensity)*deltaTime*0.005;

  coinsHolder.rotateCoins();
  ennemiesHolder.rotateEnnemies();

  sky.moveClouds();

  renderer.render(scene, camera);

  requestAnimationFrame(loop);
}

function updateDistance(){
  game.distance += game.speed*deltaTime*game.ratioSpeedDistance;
  score = Math.floor(game.distance);
  fieldDistance.innerHTML = Math.floor(game.distance);
  var d = 502*(1-(game.distance%game.distanceForLevelUpdate)/game.distanceForLevelUpdate);
  // levelCircle.setAttribute("stroke-dashoffset", d);
}

var blinkEnergy=false;

function updateEnergy(){
  game.energy -= game.speed*deltaTime*game.ratioSpeedEnergy;
  game.energy = Math.max(0, game.energy);
  energyBar.style.right = (100-game.energy)+"%";
  energyBar.style.backgroundColor = (game.energy<50)? "#f25346" : "#68c3c0";

  if (game.energy<30){
    energyBar.style.animationName = "blinking";
  }else{
    energyBar.style.animationName = "none";
  }

  if (game.energy <1){
    game.status = "gameover";
  }
}

function addEnergy(){
  clearTimeout(halfFrowningTimeout);
  clearTimeout(normalTimeout);
  setNormalEyes();

  game.energy += game.coinValue;
  game.energy = Math.min(game.energy, 100);
}

function setNormalEyes() {
  bird.rightEye.material.transparent = false;
  bird.rightEye.material.opacity = 1;
  bird.leftEye.material.transparent = false;
  bird.leftEye.material.opacity = 1;
  bird.rightEyeD.material.transparent = true;
  bird.rightEyeD.material.opacity = 0;
  bird.leftEyeD.material.transparent = true;
  bird.leftEyeD.material.opacity = 0;
  bird.rightEyeF.material.transparent = true;
  bird.rightEyeF.material.opacity = 0;
  bird.leftEyeF.material.transparent = true;
  bird.leftEyeF.material.opacity = 0;
  bird.rightEyeX.material.transparent = true;
  bird.rightEyeX.material.opacity = 0;
  bird.leftEyeX.material.transparent = true;
  bird.leftEyeX.material.opacity = 0;
}

function setHalfFrowningEyes() {
  bird.rightEye.material.transparent = true;
  bird.rightEye.material.opacity = 0;
  bird.leftEye.material.transparent = true;
  bird.leftEye.material.opacity = 0;
  bird.rightEyeF.material.transparent = false;
  bird.rightEyeF.material.opacity = 1;
  bird.leftEyeF.material.transparent = false;
  bird.leftEyeF.material.opacity = 1;
  bird.rightEyeD.material.transparent = true;
  bird.rightEyeD.material.opacity = 0;
  bird.leftEyeD.material.transparent = true;
  bird.leftEyeD.material.opacity = 0;
  bird.rightEyeX.material.transparent = true;
  bird.rightEyeX.material.opacity = 0;
  bird.leftEyeX.material.transparent = true;
  bird.leftEyeX.material.opacity = 0;
}

function setFrowningEyes() {
  bird.rightEyeD.material.transparent = false;
  bird.rightEyeD.material.opacity = 1;
  bird.leftEyeD.material.transparent = false;
  bird.leftEyeD.material.opacity = 1;
  bird.rightEyeF.material.transparent = true;
  bird.rightEyeF.material.opacity = 0;
  bird.leftEyeF.material.transparent = true;
  bird.leftEyeF.material.opacity = 0;
  bird.rightEye.material.transparent = true;
  bird.rightEye.material.opacity = 0;
  bird.leftEye.material.transparent = true;
  bird.leftEye.material.opacity = 0;
  bird.rightEyeX.material.transparent = true;
  bird.rightEyeX.material.opacity = 0;
  bird.leftEyeX.material.transparent = true;
  bird.leftEyeX.material.opacity = 0;
}

function setDeadEyes() {
  bird.rightEyeX.material.transparent = false;
  bird.rightEyeX.material.opacity = 1;
  bird.leftEyeX.material.transparent = false;
  bird.leftEyeX.material.opacity = 1;
  bird.rightEyeD.material.transparent = true;
  bird.rightEyeD.material.opacity = 0
  bird.leftEyeD.material.transparent = true;
  bird.leftEyeD.material.opacity = 0;
  bird.rightEyeF.material.transparent = false;
  bird.rightEyeF.material.opacity = 1;
  bird.leftEyeF.material.transparent = false;
  bird.leftEyeF.material.opacity = 1;
  bird.rightEye.material.transparent = true;
  bird.rightEye.material.opacity = 0;
  bird.leftEye.material.transparent = true;
  bird.leftEye.material.opacity = 0;
}

var normalTimeout, halfFrowningTimeout;

function removeEnergy(){

  const checkAuth = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true)
      }, 500)
    })
  }

  const fetchUser = () => {
    return new Promise(resolve => {
      halfFrowningTimeout = setTimeout(() => {
        setFrowningEyes();
      }, 800)
      normalTimeout = setTimeout(() => {
        setNormalEyes();
      }, 2000)
    })
  }

  async function testAwait() {
    clearTimeout(halfFrowningTimeout);
    clearTimeout(normalTimeout);
    setHalfFrowningEyes();
    const isAuth = await checkAuth();
    if (isAuth) {
      user = await fetchUser();
    }
  }

  testAwait();

  game.energy -= game.ennemyValue;
  game.energy = Math.max(0, game.energy);
}

function updatePlane(){

  game.planeSpeed = normalize(mousePos.x,-.5,.5,game.planeMinSpeed, game.planeMaxSpeed);
  var targetY = normalize(mousePos.y,-.75,.75,game.planeDefaultHeight-game.planeAmpHeight, game.planeDefaultHeight+game.planeAmpHeight);
  var targetX = normalize(mousePos.x,-1,1,-game.planeAmpWidth*.7, -game.planeAmpWidth);

  game.planeCollisionDisplacementX += game.planeCollisionSpeedX;
  targetX += game.planeCollisionDisplacementX;


  game.planeCollisionDisplacementY += game.planeCollisionSpeedY;
  targetY += game.planeCollisionDisplacementY;

  bird.mesh.position.y += (targetY-bird.mesh.position.y)*deltaTime*game.planeMoveSensivity;
  bird.mesh.position.x += (targetX-bird.mesh.position.x)*deltaTime*game.planeMoveSensivity;

  bird.mesh.rotation.z = (targetY-bird.mesh.position.y)*deltaTime*game.planeRotXSensivity;
  bird.mesh.rotation.x = (bird.mesh.position.y-targetY)*deltaTime*game.planeRotZSensivity;
  var targetCameraZ = normalize(game.planeSpeed, game.planeMinSpeed, game.planeMaxSpeed, game.cameraNearPos, game.cameraFarPos);
  camera.fov = normalize(mousePos.x,-1,1,40, 80);
  camera.updateProjectionMatrix ()

  game.planeCollisionSpeedX += (0-game.planeCollisionSpeedX)*deltaTime * 0.03;
  game.planeCollisionDisplacementX += (0-game.planeCollisionDisplacementX)*deltaTime *0.01;
  game.planeCollisionSpeedY += (0-game.planeCollisionSpeedY)*deltaTime * 0.03;
  game.planeCollisionDisplacementY += (0-game.planeCollisionDisplacementY)*deltaTime *0.01;



  let timer = 0.02 * Date.now();
  // console.log(0.5 + 0.5 * Math.sin(timer));
  bird.wingRight.rotation.x = 3.2 + 0.7 * Math.cos(timer);
  bird.wingRight.rotation.y = 0.8 + -0.2 * Math.cos(timer);
  bird.wingLeft.rotation.x = -3.2 + -0.7 * Math.cos(timer);
  bird.wingLeft.rotation.y = -0.8 + 0.2 * Math.cos(timer);

  let timerKnot = 0.006 * Date.now();

  bird.topknotBig.position.y = 59 + 1.2 * Math.cos(timerKnot);

  let timerKnotSmall = 0.006 * Date.now();

  bird.topknotSmall.position.y = 48 + 1 * Math.cos(timerKnotSmall);
}

function showReplay(){
  replayMessage.style.display="block";
}

function hideReplay(){
  replayMessage.style.display="none";
  setNormalEyes()
}

function normalize(v,vmin,vmax,tmin, tmax){
  var nv = Math.max(Math.min(v,vmax), vmin);
  var dv = vmax-vmin;
  var pc = (nv-vmin)/dv;
  var dt = tmax-tmin;
  var tv = tmin + (pc*dt);
  return tv;
}

var fieldDistance, energyBar, replayMessage, fieldLevel, levelCircle;

function init(event){

  // UI

  fieldDistance = document.getElementById("distValue");
  highScore = document.getElementById("highScore");
  energyBar = document.getElementById("energyBar");
  replayMessage = document.getElementById("replayMessage");
  fieldLevel = document.getElementById("levelValue");
  // levelCircle = document.getElementById("levelCircleStroke");

  resetGame();
  createScene();

  createLights();
  createPlane();
  createEnvironment();
  createSea();
  createSky();
  createCoins();
  createEnnemies();
  createParticles();

  document.addEventListener('mousemove', handleMouseMove, false);
  document.addEventListener('touchmove', handleTouchMove, false);
  document.addEventListener('mouseup', handleMouseUp, false);
  document.addEventListener('touchend', handleTouchEnd, false);

  loop();
}

window.addEventListener('load', init, false);
/* eslint-enable */
