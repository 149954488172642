<template>
  <div class = "wrapper">

    <section class="maintenance__page">
			<div class="maintenance__page-container clearfix">
				<div class = "maintenance__page-maincontainer clearfix">
					<div class="maintenance__page-floating-support">
						<a href="javascript:void(0);" onclick="zE.activate({hideOnClose: true});">
							<img src="@/assets/images/old-alpha/icon-support.png" alt="support icon"> Support
						</a>
					</div>
					<div class="maintenance__page-centermail">

						<h1 class = "maintenance__page-sorry">We’re sorry</h1>
						<!-- <h2>Platform <span class = "white-color black">Unavailable</span></h2> -->
						<div class = "maintenance__page-bot-text">
							<div class="maintenance__page-cog-container">
								<div class="maintenance__page-cog1"><img src="@/assets/images/old-alpha/cog1.png" alt = "Maintenance Big Cog"></div>
								<div class="maintenance__page-cog2"><img src="@/assets/images/old-alpha/cog2.png" alt = "Maintenance Small Cog"></div>
							</div>
							<h3 class="maintenance__page-content">Platform is currently undergoing maintenance.</h3>
							<p class="maintenance__page-info">Please be patient while we bring things back online.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

    <div class="maintenance__game-holder" id="gameHolder">
      <div class="maintenance">
        <h1 class="maintenance__title">We're sorry</h1>
        <div class="maintenance__container">
          <span class="maintenance__container-title">Platform is currently undergoing maintenance.</span>
          <span class="maintenance__container-subtitle">Please be patient while we bring things back online.</span>
        </div>
      </div>
      <div class="maintenance__score" >
        <div class="maintenance__score-section" id="score">
          <div class="maintenance__score-content" id="level">
            <div class="maintenance__score-block">
              <div class="maintenance__score-label">level</div>
              <div class="maintenance__score-value maintenance__score-value_level" id="levelValue">1</div>
            </div>
            <!-- <svg class="level-circle" id="levelCircle" viewbox="0 0 200 200">
              <circle id="levelCircleBgr" r="80" cx="100" cy="100" fill="none" stroke="#fff" stroke-width="24px" />
              <circle id="levelCircleStroke" r="80" cx="100" cy="100" fill="none" #f25346 stroke="#68c3c0" stroke-width="14px" stroke-dasharray="502" />
            </svg> -->
          </div>
          <div class="maintenance__score-content" id="dist">
            <div class="maintenance__score-label maintenance__score-label_orange">distance</div>
            <div class="maintenance__score-value maintenance__score-value_dist" id="distValue">000</div>
          </div>
          <div class="maintenance__score-content" id="high-score">
            <div class="maintenance__score-label maintenance__score-label_orange">High Score</div>
            <div class="maintenance__score-value maintenance__score-value_score" id="highScore">0</div>
          </div>
        </div>
        <div class="maintenance__score-value maintenance__score-value_energy">
          <div class="maintenance__score-energy-bar" id="energyBar"></div>
        </div>
      </div>
      <div class="maintenance__bottom-desc">
        <div class="maintenance__bottom-desc-link">
          <p class="maintenance__bottom-desc-text">If you are facing difficulties accessing a certain page, please email us at :</p>
          <a href="mailto:support@sparrowexchange.com" class="maintenance__bottom-desc-support">ops@sparrowexchange.com</a>
        </div>
      </div>
      <div class="maintenance__world" id="world"></div>
      <div class="maintenance__message maintenance__message_replay" id="replayMessage">
        <span class="maintenance__message_middle">
          <img class="maintenance__message_img" src="@/assets/images/old-alpha/load.png" alt="loading">Restart Game
        </span>
      </div>
    </div>
	</div>
</template>
<script>
import '../assets/js/game.js';
export default {
  name: 'Maintenance',
  components: {
  },
  data() {
    return {
    }
  },
  mounted:function(){
    setTimeout(() => {
      this.setMaintenance()
    }, 1000)
  },
  methods: {
    setMaintenance() {
      let maintenanceInterval = setInterval(() => {
        this.setMaintenance();
        clearInterval(maintenanceInterval);
      },5000);

      this.$http.get(this.createApiUrlPricing('get_platform_status')).then((res) =>
      {
        if (res.body.api_status==1&&res.body.pricing_status==1) {
          clearInterval(maintenanceInterval);
          window.location = 'dashboard/#/checker'
        } else if(res.body.api_status==0||res.body.pricing_status==0) {
          maintenanceInterval
        }
      },err => {
        console.log(err)
      })

    }
  }
}
</script>
